.hero{
    display: flex;
    justify-content: space-between;
}
.left-h{
    padding: 2rem;
    padding-top: 1.5rem;
    flex:3;
    display: flex;
    gap: 2rem;
    flex-direction: column;
}
.right-h{
    flex:1;
    background-color: var(--orange);
    position: relative;
}
.the-best-ad{
    margin-top: 4rem;
    background-color: #363d42;
    padding: 20px 13px;
    border-radius: 4rem;
    width:fit-content;
    text-transform: uppercase;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: white;
}
.the-best-ad div{
    position: absolute;
    border-radius: 3rem;
    height:80%;
    left:8px;
    background-color: var(--orange);
    width:5.4rem;
    z-index: 1;
}
.the-best-ad span{
    z-index: 2;
}
.the-hero-text{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    text-transform: uppercase;
    font-size: 4.5rem;
    font-weight: bold;
    color: white;
    text-overflow: inherit;
}
.the-hero-text div:nth-of-type(3){
width:80%;
font-size: 1rem;
letter-spacing: 1px;
font-weight: 200;
text-transform: none;
}
.figures{
    display: flex;
    gap:2rem;
}
.figures div{
    display: flex;
    flex-direction: column;
}
.figures div span:nth-of-type(1){
    color: white;
    font-size: 2rem;
}
.figures div span:nth-of-type(2){
    color: var(--gray);
}
.hero-buttons{
    display: flex;
    gap:1rem;
    font-weight: normal;
}
.hero-buttons button:nth-of-type(1){
    color: white;
    background-color: var(--orange);
}
.hero-buttons button:nth-of-type(2){
    border-color: var(--orange);
    color: white;
    background-color: transparent;
}
.right-h .btn{
    position: absolute;
    right:3rem;
    top:2rem;
    color:black;
}
.heart-rate{
    display: flex;
    flex-direction: column;
    gap:1rem;
    background-color: var(--darkGrey);
    width: fit-content;
    padding:1rem;
    align-items: flex-start;
    border-radius: 5px;
    position: absolute;
    right:4rem;
    top:7rem;
}
.heart-rate span:nth-of-type(1){
    color:var(--gray);

}
.heart-rate span:nth-of-type(2){
    color:white;
    font-size: 1.5rem;
}
.heart-rate img{
    width:2rem;
}
.hero_image{
    position: absolute;
    right:8rem;
    top:10rem;
    width:23rem;
}
.hero_image_back{
    position: absolute;
    z-index: -1;
    top:4rem;
    right:20rem;
    width:15rem;
}
.calories{
    display: flex;
    gap:2rem;
    background-color: var(--caloryCard);
    position :absolute;
    border-radius: 5px;
    padding: 1rem;
    width:fit-content;
    right:28rem;
    top:32rem;
}
.calories img{
    width:3rem;
}
.calories div{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.calories div span:nth-of-type(1){
    color: var(--gray);
}
.calories div span:nth-of-type(2){
    color: white;
    font-size: 1.5rem;
}
.hero-blur{
    width:22rem;
    height: 30rem;
    left:0;
}
@media screen and (max-width: 768px) {
    .hero{
        flex-direction: column;
    }
    .hero-blur{
        width:14rem;
    }
    .the-best-ad{
        margin-top: 0;
        align-self: center;
        font-size: small;
        transform: scale(0.8);
    }
    .the-hero-text{
        font-size: xx-large;
        align-items: center;
        justify-content: center;
    }
    .the-hero-text>div:nth-child(3){
        font-size: small;
        font-weight: 200;
        text-align: center;
        letter-spacing: 1px;
    }
    .hero-buttons{
        justify-content: center;
    }
    .figures>div>span:nth-child(1){
        font-size: large;
    }
    .figures>div>span:nth-child(2){
        font-size: small;
    }
    .right-h{
        position: relative;
        background: none;
    }
    .heart-rate{
        left:1rem;
        top:2rem;
    }
    .right-h>button{
        left:10.5rem;
        top:2rem;
        width:6rem;
    }
    .calories{
        top:5rem;
        left:2rem;
        position: relative;
    }
    .calories>img{
        width:2rem;
    }
    .calories div span:nth-of-type(2){
        color: white;
        font-size: 1rem;
    }
    .hero_image{
        left:7rem;
        top:4rem;
        width:15rem;
        position: relative;
        align-self: center;
    }
    .hero_image_back{
        top:0rem;
        left:2rem;
        width:15rem;
    }
}

.Footer-Container{
    position: relative;
}
.footer{
    display: flex;
    flex-direction: column;
    padding: 1rem 2rem;
    justify-content: center;
    align-items: center;
    gap:4rem;
    height:20rem;
    
}
.Footer-Container>hr{
    border:1px solid var(--lightgray);
    width:100%;
}
.social-links{
    display: flex;
    gap:4rem;
}
.social-links>img{
    width:2rem;
    height: 2rem;
    cursor: pointer;
}
.logo>img{
    width:10rem;
}
.footer-blur2{
    right:15%;
    bottom:0;
    width:26rem;
    height: 12rem;
    filter: blur(200px);
    background: red;
}
.footer-blur1{
    left:15%;
    bottom:0;
    width:26rem;
    height: 12rem;
    filter: blur(200px);
    background:rgb(255,115,0);
}


.Join{
display: flex;
gap:10rem;
padding:0 2rem;
}
.left-j{
    display: flex;
    flex-direction: column;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 3rem;
    position: relative;

}
.left-j>div{
    display: flex;
    gap:1rem;
}
.left-j>hr{
    border:2px solid var(--orange);
    position: absolute;
    width:10.5rem;
    border:20%;
    margin:-10px 0;
}
.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.email-container{
    display: flex;
    gap:3rem;
    background-color: gray;
    padding:1rem 2rem;
}
.email-container>input{
    background-color: transparent;
    color: var(--lightgray);
    border: none;
    outline:none;
}
.email-container>input::placeholder{
    color: var(--lightgray);
}
.email-container>button{
    color: white;
    background-color: var(--orange);
}
@media screen and (max-width:768px) {
    .Join{
        flex-direction: column;
        gap:1rem;
    }
    .left-j{
        font-size: x-large;
    }
    .email-container{
        display: flex;
        gap:1rem;
        width:16rem;    
    }

}
.Testimonials{
    display: flex;
    gap:1rem;
    padding:0 2rem;
}
.left-t{
    display: flex;
    flex-direction: column;
    gap:2rem;
    flex: 1 1;
    color:white;
}
.left-t>:nth-child(1){
    font-weight: bold;
    color:var(--orange);
}
.left-t>:nth-child(2),
.left-t>:nth-child(3){
    font-weight: bold;
    font-size: 3rem;
}
.left-t>:nth-child(4){
    text-align: justify;
    letter-spacing: 2px;
    line-height: 40px;
}
.left-t>:nth-child(5){
    text-transform: uppercase;
}
.right-t{
    flex:1;
    position: relative;
}
.right-t>img{
    position: absolute;
    width:17rem;
    height:20rem;
    object-fit:cover;
    right:8rem;
    top:2rem;
}
.right-t>:nth-child(1){
position:absolute;
width:17rem;
height:20rem;
top:0.9rem;
right:9rem;
border:2px solid orange;
background-color: transparent;
}
.right-t>:nth-child(2){
    position:absolute;
    width:17rem;
    height:19rem;
    top:4rem;
    right:7rem;
    border:2px solid orange;
    background:var(--planCard);
}
.arrows{
    display: flex;
    position: absolute;
    bottom:1rem;
    left:3rem;
    gap:1rem;
}
.arrows>img{
    width:1.5rem;
    cursor: pointer;
}
@media screen and (max-width:768px) {
    .Testimonials{
        display: flex;
        flex-direction: column;
    }
    .left-t>:nth-child(2),
    .left-t>:nth-child(3){
        font-size: 2rem;
    }
    .right-t{
    display: flex;
    flex-direction: column;
    gap:2rem;
    justify-content: center;align-items: center;

    }
    .right-t>img{
        right:0rem;
        width:15rem;
        top:2rem;
        position: relative;
    }
    .right-t>:nth-child(1){
        width:15rem;
        height:20rem;
        right:1rem;;
        }
        .right-t>:nth-child(2){
            width:15rem;
            top:4rem;
            right:-1rem;
        }
        .arrows{
            display: flex;
            position: relative;
            bottom:0rem;
            left:0;
            gap:1rem;
            top:3rem;
        }

}